// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Badge from "../styleguide/components/Badge/Badge.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function statusFromString(string) {
  switch (string) {
    case "approved" :
        return "Approved";
    case "blocked" :
        return "Blocked";
    case "pending" :
        return "Pending";
    default:
      return PervasivesU.failwith("Unknown status");
  }
}

function statusToString(status) {
  switch (status) {
    case "Pending" :
        return "pending";
    case "Approved" :
        return "approved";
    case "Blocked" :
        return "blocked";
    
  }
}

function fromJs(js) {
  return {
          id: js.id,
          email: js.email,
          status: js.status,
          isValid: js.isValid,
          invalidAttempts: js.invalidAttempts,
          blockedAt: js.blockedAt,
          timeoutAt: js.timeoutAt,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              status: field.required("status", Json_Decode$JsonCombinators.string),
              isValid: field.required("isValid", Json_Decode$JsonCombinators.bool),
              invalidAttempts: field.required("invalidAttempts", Json_Decode$JsonCombinators.$$int),
              blockedAt: Js_null_undefined.fromOption(field.required("blockedAt", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))),
              timeoutAt: Js_null_undefined.fromOption(field.required("timeoutAt", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function fromJs$1(js) {
  return {
          id: js.id,
          email: js.email,
          status: js.status,
          isValid: js.isValid,
          invalidAttempts: js.invalidAttempts,
          createdAt: js.createdAt
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              status: field.required("status", Json_Decode$JsonCombinators.string),
              isValid: field.required("isValid", Json_Decode$JsonCombinators.bool),
              invalidAttempts: field.required("invalidAttempts", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

function fromJs$2(js) {
  return {
          id: js.id,
          ipAddress: js.ipAddress,
          status: js.status,
          invalidAttempts: js.invalidAttempts
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              ipAddress: field.required("ipAddress", Json_Decode$JsonCombinators.string),
              status: field.required("status", Json_Decode$JsonCombinators.string),
              invalidAttempts: field.required("invalidAttempts", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJs$3(js) {
  return {
          suspiciousIps: Belt_Array.map(js.suspiciousIps, fromJs$2),
          totalSuspiciousIps: js.totalSuspiciousIps,
          totalPages: js.totalPages,
          currentPage: js.currentPage
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              suspiciousIps: field.required("suspiciousIps", Json_Decode$JsonCombinators.array(decoder$2)),
              totalSuspiciousIps: field.required("totalSuspiciousIps", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Index = {
  fromJs: fromJs$3,
  decoder: decoder$3,
  fromJson: fromJson$2
};

var SuspiciousIp = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  Index: Index
};

var Dashboard = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1,
  SuspiciousIp: SuspiciousIp
};

function SuspiciousEmail$Badge$Status(props) {
  switch (props.status) {
    case "Pending" :
        return JsxRuntime.jsx(Badge.Span.make, {
                    id: "suspiciousEmailStatus",
                    color: "Gray",
                    children: "Pending"
                  });
    case "Approved" :
        return JsxRuntime.jsx(Badge.Span.make, {
                    id: "suspiciousEmailStatus",
                    color: "Blue",
                    children: "Approved"
                  });
    case "Blocked" :
        return JsxRuntime.jsx(Badge.Span.make, {
                    id: "suspiciousEmailStatus",
                    color: "Yellow",
                    children: "Blocked"
                  });
    
  }
}

var Status = {
  make: SuspiciousEmail$Badge$Status
};

function SuspiciousEmail$Badge$IsValid(props) {
  if (props.isValid) {
    return JsxRuntime.jsx(Badge.Span.make, {
                id: "suspiciousEmailIsValid",
                color: "Green",
                children: "Valid"
              });
  } else {
    return JsxRuntime.jsx(Badge.Span.make, {
                id: "suspiciousEmailIsValid",
                color: "Red",
                children: "Invalid"
              });
  }
}

var IsValid = {
  make: SuspiciousEmail$Badge$IsValid
};

var Badge$1 = {
  Status: Status,
  IsValid: IsValid
};

function fromJs$4(js) {
  return {
          id: js.id,
          email: js.email,
          status: js.status,
          isValid: js.isValid,
          invalidAttempts: js.invalidAttempts,
          blockedAt: js.blockedAt,
          timeoutAt: js.timeoutAt,
          suspiciousIpsIndex: fromJs$3(js.suspiciousIpsIndex),
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              status: field.required("status", Json_Decode$JsonCombinators.string),
              isValid: field.required("isValid", Json_Decode$JsonCombinators.bool),
              invalidAttempts: field.required("invalidAttempts", Json_Decode$JsonCombinators.$$int),
              blockedAt: Js_null_undefined.fromOption(field.required("blockedAt", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))),
              timeoutAt: Js_null_undefined.fromOption(field.required("timeoutAt", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))),
              suspiciousIpsIndex: field.required("suspiciousIpsIndex", decoder$3),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var Edit = {
  fromJs: fromJs$4,
  decoder: decoder$4,
  fromJson: fromJson$3
};

export {
  statusFromString ,
  statusToString ,
  fromJs ,
  decoder ,
  fromJson ,
  Dashboard ,
  Badge$1 as Badge,
  Edit ,
}
/* decoder Not a pure module */
